exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-quiz-quiz-category-tsx": () => import("./../../../src/pages/quiz/quiz-category.tsx" /* webpackChunkName: "component---src-pages-quiz-quiz-category-tsx" */),
  "component---src-pages-quiz-quiz-sub-category-quiz-comment-tsx": () => import("./../../../src/pages/quiz/quizSubCategory/quiz-comment.tsx" /* webpackChunkName: "component---src-pages-quiz-quiz-sub-category-quiz-comment-tsx" */),
  "component---src-pages-quiz-tsx": () => import("./../../../src/pages/quiz.tsx" /* webpackChunkName: "component---src-pages-quiz-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-quiz-sub-category-tsx": () => import("./../../../src/templates/quiz-sub-category.tsx" /* webpackChunkName: "component---src-templates-quiz-sub-category-tsx" */)
}

