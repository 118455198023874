import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.GATSBY_API_URL}`,
});

api.interceptors.response.use(
  (response) => response,
  (error: { response: { data: unknown } }) => {
    return Promise.reject(error.response.data);
  }
);

export { api };
