import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { api } from "./api";

interface Props {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: ({ queryKey: [url] }) =>
        api.get(`${url}`).then((res) => res.data),
      retry: false,
    },
  },
});

export const RootElement = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer position="bottom-right" theme="dark" />
      {children}
    </QueryClientProvider>
  );
};
